import { createEvent, createStore } from '@cobuildlab/react-simple-state';
import { DateFilter, DEFAULT_TIMEZONE } from '../../shared/constants';
import { CountData, DashboardCouponUsedType, GenerateUserReport, WinnerNote } from './dashboard-types';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { CouponTypes } from '../tenant-subscription-plans/tenant-subs-plan-types';
import { CouponRedemptionType } from '../tenant-subscription-plans/coupons/coupon-types';

export const currentTime = momentTimezone()
  .tz(DEFAULT_TIMEZONE)
  .startOf('day')
  .format();

export const fetchUsersCountStore = createStore<{
  loading: boolean;
  users: CountData[];
}>({
  initialValue: {
    loading: true,
    users: [],
  },
});

export const changeDateFilterStore = createStore<{ date: DateFilter }>({
  initialValue: {
    date: DateFilter.TODAY,
  },
});

export const changeDashboardDateFilterStore = createStore<{
  from: string;
  to: string;
  filter: DateFilter;
}>({
  initialValue: {
    from: moment().utc(true).startOf('day').format(),
    to: moment().utc(true).endOf('day').format(),
    filter: DateFilter.TODAY,
  },
});

export const changeWinnerNotesOrderStore = createStore<{
  order: 'asc' | 'desc';
}>({
  initialValue: {
    order: 'desc',
  },
});

export const winnerNoteEvents = createEvent<{
  data: WinnerNote[];
  total: number;
}>({
  initialValue: {
    data: [],
    total: 0,
  },
});

export const winnerNoteErrorEvents = createEvent<Error>();

export const winnerNotePaginateEvents = createEvent<{
  data: WinnerNote[];
  total: number;
}>({
  initialValue: {
    data: [],
    total: 0,
  },
});

export const winnerNoteErrorPaginateEvents = createEvent<Error>();

export const changeGoogleDateFilterStore = createStore<{
  from: string;
  to: string;
  filter: DateFilter;
}>({
  initialValue: {
    from: moment(currentTime).toISOString(),
    to: moment().toISOString(),
    filter: DateFilter.TODAY,
  },
});
export const selectedSectionStore = createStore<{ section: string }>({
  initialValue: {
    section: '',
  },
});

export const fetchGenerateUserReportEvent = createEvent<{
  report: GenerateUserReport[];
}>({
  initialValue: {
    report: [],
  },
});

export const fetchGenerateUserReportErroEvent = createEvent<Error>();

export const fetchGenerateWinnerReportEvent = createEvent<{
  report: WinnerNote[];
}>({
  initialValue: {
    report: [],
  },
});

export const fetchGenerateWinnerReportErroEvent = createEvent<Error>();


export const fetchLoginUserSessionEvent = createEvent();
export const fetchLoginUserSessionErrorEvent = createEvent();

export const fetchAnonymousUserSessionEvent = createEvent();
export const fetchAnonymousUserSessionErrorEvent = createEvent();

export const fetchCouponReportByFilterEvent =
  createEvent<DashboardCouponUsedType>({
    initialValue: {
      sharedCouponsWithRedemptionsCount: 0,
      totalRedemptionsCount: 0,
      gruopByCouponType: [],
    },
  });

export const fetchCouponReportByFilterEventError = createEvent<Error>();

export const fetchCouponRedemptionsListEvent = createEvent<{
  data: CouponRedemptionType[];
  total: number;
}>({
  initialValue: {
    data: [],
    total: 0,
  },
});
export const fetchCouponRedemptionsListEventError = createEvent<Error>();
