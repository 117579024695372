import {
  createAction,
  createStoreAction,
} from '@cobuildlab/react-simple-state';
import {
  createTenantSubsPlanStripeCouponEvent,
  createTenantSubsPlanStripeCouponEventError,
  fetchTenantSubscriptionEvent,
  fetchTenantSubscriptionEventError,
  TenantSubscriptionListEvent,
  TenantSubscriptionListEventError,
  tenantSubsPlanStripeCouponListEvent,
  tenantSubsPlanStripeCouponListEventError,
} from './tenant-subs-plan-events';
import { SortTableInput, Tenant } from '../../shared/types/generated';
import axios from 'axios';
import {
  TenantSubscriptionListType,
  TenantSubscriptionPlan,
  TenantSubsPlanStripeCouponListType,
  TenantSubsPlanStripeCouponType,
} from './tenant-subs-plan-types';
import { tenantSubscriptionPlansFilterStore } from './tenant-subs-plan-store';
import moment from 'moment';

export const tenantSubscriptionPlansFilterStoreAction = createStoreAction(
  tenantSubscriptionPlansFilterStore,
  (prev, status: string, isPremium: string) => ({
    ...prev,
    status,
    isPremium,
  }),
);

export const fetchTenantSubscriptionList = createAction(
  TenantSubscriptionListEvent,
  TenantSubscriptionListEventError,
  async (data: {
    tenant: Tenant;
    page: number;
    pageSize: number;
    status: string;
    isPremium: string;
  }) => {
    const { tenant, page, pageSize, status, isPremium } = data;
    const url = `${tenant.webhook}/api/plans`;

    const response = await axios.get<TenantSubscriptionListType>(url, {
      params: { page, pageSize, status, isPremium },
      timeout: 60000,
    });

    return response.data;
  },
);

export const fetchTenantSubscriptionPlan = createAction(
  fetchTenantSubscriptionEvent,
  fetchTenantSubscriptionEventError,
  async (data: { tenant: Tenant; id: string }) => {
    const { id, tenant } = data;
    const url = `${tenant.webhook}/api/plans/${id}`;

    const response = await axios.get<TenantSubscriptionPlan>(url, {
      timeout: 60000,
    });

    return response.data;
  },
);

export const fetchtenantSubsPlaneCouponList = createAction(
  tenantSubsPlanStripeCouponListEvent,
  tenantSubsPlanStripeCouponListEventError,
  async (data: {
    tenant: Tenant;
    page: number;
    pageSize: number;
    status: string;
    expired: boolean;
    orderBy?: SortTableInput;
  }) => {
    const { tenant, page, pageSize, status, expired, orderBy } = data;
    const url = `${tenant.webhook}/api/coupons`;

    try {
      const response = await axios.get<TenantSubsPlanStripeCouponListType>(
        url,
        {
          params: {
            page,
            pageSize,
            status,
            includeExpired: expired,
            orderByField: orderBy?.field,
            orderByOrder: orderBy?.order,
          },
          timeout: 60000,
        },
      );

      return response.data;
    } catch (error: any) {
      const msg =
        error?.response?.data?.message ?? 'Error al obtener los cupones';
      throw new Error(msg);
    }
  },
);

export const createTenantSubsPlanCoupon = createAction(
  createTenantSubsPlanStripeCouponEvent,
  createTenantSubsPlanStripeCouponEventError,
  async (data: {
    tenant: Tenant;
    plans: string[];
    name: string;
    discount: number;
    discountType: string;
    maxRedemptions?: number | null;
    expirationDate?: string | null;
    durationInMonths?: number | null;
    isPublic?: boolean;
    isForSharing?: boolean;
  }) => {
    const { tenant, plans } = data;
    const url = `${tenant.webhook}/api/create-coupon`;

    try {
      const response = await axios.post<TenantSubsPlanStripeCouponType>(url, {
        couponName: data.name,
        discount: data.discount,
        discountType: data.discountType,
        maxRedemptions: data.maxRedemptions,
        durationInMonths: data.durationInMonths,
        expirationDate: data?.expirationDate
          ? moment(data.expirationDate).toISOString()
          : null,
        isPublic: data.isPublic,
        isForSharing: data.isForSharing,
        plans,
      });
      return response.data;
    } catch (error: any) {
      const msg = error?.response?.data?.message ?? 'Error al crear el cupón';
      throw new Error(msg);
    }
  },
);
